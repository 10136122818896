import classes from "./Footer.module.css";

export const Footer = () => {
    const currentDate = new Date().getFullYear();
    return (
        <div className={classes.Footer}>
            <div className='wrapper text-center'>
                <p>
                    &copy; {currentDate} Profair Games
                </p>
            </div>
        </div>
    )
}