import classes from "./GamesItem.module.css";

export const GamesItem = ({game}) => {
    const openTheGame = () => {
        window.open(game.link, "_blank");
    }

    return (
        <div className={`wrapper ${classes.GamesItem}`} key={game.title}>
            <div className={`row ${classes.GamesFlex}`}>
                 <div className={`col-xs-12 col-md-6 ${classes.GamesImage}`}>
                    <img src={game.image} alt={game.title} onClick={openTheGame} />
                </div>
                <div className={`col-xs-12 col-md-6 ${classes.GamesResponsive}`}>
                    <h3>{game.title}</h3>
                    <p>{game.description}</p>
                </div>
            </div>
        </div>
    )
}