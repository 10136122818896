import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import classes from "./Services.module.css";

export const Services = (props) => {
  const iconsStorage = [
    <FontAwesomeIcon icon={solid("user-tie")} />,
    <FontAwesomeIcon icon={solid("address-book")} />,
  ]

  return (
    <div id='services' className={`text-center ${classes.Services}`}>
      <div className='wrapper'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            ProFair Games helps to maintain high-performance levels consistently and provide cost-effective solutions using the most updated tools and technologies
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-6'>
                  {iconsStorage[i]}
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
