import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";

export const Slider = (props) => {
    let [items, setItems] = useState();
    useEffect(() => {
        setItems(props.generateItems());
    }, []);

    return (
        items ?
            (
                <Swiper
                    autoHeight={true}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={false}
                    navigation={props.arrows}
                    modules={[Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {items.map((elem) => (
                        <SwiperSlide key={elem.key}>{elem}</SwiperSlide>
                    ))}
                </Swiper>
            )
            : null
    );
}