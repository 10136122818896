import CryptowarsImage from "../../img/games/cryptowars.png";
import ThePandaDanceImage from "../../img/games/the_panda_dance.png";
import FrenchRouletteImage from "../../img/games/french_roulette.png";
import TheBankHeistImage from "../../img/games/bank_heist.png";
import RussianRouletteImage from "../../img/games/russian_roulette.png";
import TeenPattiImage from "../../img/games/teen_patti.png";

const gameData = (image, title, link, description) => {
    return {
        image,
        title,
        link,
        description
    }
}

export const data = [
    gameData(
        CryptowarsImage,
        "Cryptowars",
        "https://dev.games.profairgames.com/slot/cryptowars/",
        "Cryptowars focuses on a topic that almost all of us are discussing. It has 95% RTP, 4-rows, 5-reels, and a captivating 1,024 winning ways, revealing how generous this game is. It is the game of the moment.So,get on the trend!"
    ),
    gameData(
        ThePandaDanceImage,
        "The Panda Dance",
        "https://dev.games.profairgames.com/slot/the_panda_dance/",
        "Panda Dance is a video slot title with 30 fixed pay-lines and a 94% Return to Player ratio. There are 5-reels on 3-rows on it - the type you will like to play. Enjoy it!"
    ),
    gameData(
        FrenchRouletteImage,
        "French Roulette",
        "https://dev.games.profairgames.com/table/french_roulette/",
        "With the La Partage rule, the single 0, and the 98.65% RTP, this promises to be one of the best paying roulette games out there. If you want to have more winning chances, you need to play this roulette game now."
    ),
    gameData(
        TheBankHeistImage,
        "The Bank Heist",
        "https://dev.games.profairgames.com/slot/the_bank_heist/",
        "The Bank Heist comes with 20 pay-lines and 5 reels on 3 rows. It is a video slot with an RTP of up to 95.7%. You will enjoy icons like banknotes, robbers, policemen, and paying symbols like the scatter and wild in the game."
    ),
    gameData(
        RussianRouletteImage,
        "Russian Roulette",
        "https://dev.games.profairgames.com/table/russian_roulette/",
        "Russian Roulette is a game of chance. You get a revolver gun with 1 bullet in the chamber. You can roll the chamber before shooting. Last player alive is the winner."

    ),
    gameData(
        TeenPattiImage,
        "Teen Patti",
        "https://dev.games.profairgames.com/cards/teen_patti/",
        "Teen Patti is a card game resembling poker. The game is played between the player and the house (3 cards for each), using a standard deck of 52 cards. The RTP for the Teen Patti game is 97,58% which makes this game very important in the market.",
    )
];