import { GamesItem } from "../gamesItem/GamesItem";
import { data } from "../data";
import classes from "./Games.module.css";
import { Button } from "../../button/Button";
import { Slider } from '../../slider/Slider'

export const Games = () => {
    const generateGames = () => {
        return data.map(game => {
            return <GamesItem key={game.title} game={game} />
        })
    }

    const redirect = () => {
        window.open("https://stg-lobby.profairgames.com/", "_blink")
    }

    return (
        <div id="games" className={classes.Games}>
            <h2>List of our games</h2>
            <Slider generateItems={generateGames} arrows={true}></Slider>
            <div className={`wrapper ${classes.GamesButtonContainer}`} onClick={redirect}>
                <Button text="More games " />
            </div>
        </div>
    )
}