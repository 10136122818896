import classes from "./Nav.module.css";

export const Nav = () => {
    return (
        <ul className={`nav ${classes.NavFlex}`}>
            <li>
                <a href='#about' className='page-scroll'>
                    About
                </a>
            </li>
            <li>
                <a href='#services' className='page-scroll'>
                    Services
                </a>
            </li>
            <li>
                <a href='#games' className='page-scroll'>
                    Our games
                </a>
            </li>
            <li>
                <a href='#contact' className='page-scroll'>
                    Contact
                </a>
            </li>
        </ul>
    )
}