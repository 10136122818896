import AboutLogo from "../../img/about2.png";
import classes from "./About.module.css";

export const About = (props) => {
  return (
    <div id="about" className={classes.About}>
      <div className="wrapper">
        <div className={`row ${classes.AboutContent}`}>
          <div className="col-xs-12 col-md-6">
            <img src={AboutLogo} className={`img-responsive ${classes.AboutImage}`} alt="" />
          </div>
          <div className={`col-xs-12 col-md-6 ${classes.AboutResponsive}`}>
            <div className={classes.AboutText}>
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
