import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation/Navigation";
import { Header } from "./components/header/Header";
import { About } from "./components/about/About";
import { Services } from "./components/services/Services";
import { Contact } from "./components/contact/Contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Footer } from "./components/footer/Footer";
import { Games } from "./components/games/games/Games";
import "swiper/swiper-bundle.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="main">
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Games />
      <Services data={landingPageData.Services} />
      <Contact data={landingPageData.Contact} />
      <Footer />
    </div>
  );
};

export default App;
