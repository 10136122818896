import { ScreenshotsItem } from "../screenshotsItem/ScreenshotsItem";
import { data } from "../data";
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper";

export const Screenshots = () => {
    let [items, setItems] = useState();
    useEffect(() => {
        setItems(data.map(screenshot => {
            return <ScreenshotsItem key={screenshot.title} screenshot={screenshot} />
        }));
    }, []);

    return (
        items ?
            (
                <Swiper
                    id="screenshots"
                    slidesPerView={1}
                    effect={"fade"}
                    loop={true}
                    pagination={false}
                    navigation={false}
                    modules={[EffectFade, Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {items.map((elem) => (
                        <SwiperSlide key={elem.key} style={{ width: "100%" }}>{elem}</SwiperSlide>
                    ))}
                </Swiper>
            )
            : null
    )
}