import { useState } from 'react'
import classes from "./Contact.module.css";
import emailjs from 'emailjs-com'
import {Button} from "../button/Button";
import {ContactInfo} from "./contactInfo/ContactInfo";

const initialState = {
  name: '',
  email: '',
  message: '',
}

const SERVICE_ID = "service_dgwjx1n";
const TEMPLATE_ID = "template_v8nwtxo";
const USER_ID = "user_O8iJBNLktZO5NpTLvLPKk";

export const Contact = (props) => {
  // eslint-disable-next-line
  const [state, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const clearInputs = () => {
    const inputs = document.querySelectorAll("input");
    const textarea = document.querySelectorAll("textarea");
    [...inputs, ...textarea].forEach(item => item.value = "");
  }

  const isMessageAboutSuccess = () => {
    const successDiv = document.querySelector(".successSend");
    successDiv.innerHTML = "Message is successfully sent."
    setTimeout(() => {
      successDiv.innerHTML = "";
    }, 1500)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then(() => {
          isMessageAboutSuccess();
          clearInputs();
          clearState()
        }, (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <div>
      <div id='contact' className={classes.Contact}>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className={`section-title ${classes.ContactSection}`}>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success' className="successSend"></div>
                <Button text="Send Message" type="submit" />
              </form>
            </div>
          </div>
          <ContactInfo data={props.data}/>
        </div>
      </div>
    </div>
  )
}
