import LogoImage from "../../../img/about.png";
import classes from "./Logo.module.css";

export const Logo = () => {
    return (
        <img
            src={LogoImage}
            alt="Logo"
            className={classes.Logo}
            href='#page-top'
        />
    )
}