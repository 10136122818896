import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export const ContactInfo = (props) => {
    return (
        <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
                <h3>Contact Info</h3>
                <p>
                    {props.data ? props.data.address : 'loading'}
                </p>
            </div>
            <div className='contact-item'>
                <p>
                    <span>
                        <FontAwesomeIcon icon={solid('envelope')} /> &nbsp;
                        Email
                    </span>
                </p>
                <p>
                    {props.data ? props.data.email : 'loading'}
                </p>
            </div>
        </div>
    )
}