import {Logo} from "./logo/Logo";
import {Nav} from "./nav/Nav";
import classes from "./Navigation.module.css";

export const Navigation = () => {
  return (
    <nav className={`navbar navbar-dark ${classes.NavigationBar}`}>
      <div className='wrapper'>
        <div className={classes.Navigation}>
          <Logo />
          <Nav />
        </div>
      </div>
    </nav>
  )
}
