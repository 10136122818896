import classes from "./ScreenshotsItem.module.css";


export const ScreenshotsItem = ({ screenshot }) => {
    return (
        <div>
            <img src={screenshot.image} alt={screenshot.title} className={classes.screenshotImg} />
        </div>

    )
}